var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `LAUNCHPAD_FORM` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            visible: _vm.show,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            id: "launchpad-modal",
            scrollable: "",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.modalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _vm.canView("STORAGE_FILE")
            ? _c("AvatarBanner", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  baseAvatarIcon: ["fad", "user-tie"],
                  bannerOnly: true,
                },
                on: { status: _vm.avatarBannerStatus },
                model: {
                  value: _vm.avatarBanner,
                  callback: function ($$v) {
                    _vm.avatarBanner = $$v
                  },
                  expression: "avatarBanner",
                },
              })
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      class: { "mb-0": _vm.showNameError },
                      attrs: {
                        label: _vm.$t("dashboard.launchpad.field.name"),
                        "label-for": "name",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }",
                              },
                            ],
                            attrs: {
                              id: "name",
                              type: "text",
                              "data-vv-as": _vm.$t(
                                "dashboard.launchpad.field.name"
                              ),
                              "data-vv-name": "launchpad.name",
                              "data-vv-delay": "500",
                              readonly: _vm.isReadOnly,
                              state: _vm.fieldValidateUtil.stateValidate(
                                _vm.isReadOnly,
                                _vm.veeFields,
                                _vm.errors,
                                "dashboard.launchpad.field.name"
                              ),
                              autofocus: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.launchpad.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.launchpad, "name", $$v)
                              },
                              expression: "launchpad.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showNameError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("launchpad.name")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dashboard.launchpad.field.description"),
                "label-for": "description",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "description",
                      readonly: _vm.isReadOnly,
                      rows: 3,
                      "max-rows": 6,
                      trim: "",
                    },
                    model: {
                      value: _vm.launchpad.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.launchpad, "description", $$v)
                      },
                      expression: "launchpad.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t(`dashboard.launchpad.field.link`))),
              ]),
              _vm._l(_vm.launchpad.links, function (link, index) {
                return _c(
                  "b-row",
                  { key: index },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            class: { "mb-0": _vm.showUrlError(index) },
                            attrs: {
                              label: _vm.$t(
                                "dashboard.launchpad.url_and_button"
                              ),
                              "label-for": `link_url` + index,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: true },
                                      expression: "{ required: true }",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: `link_url` + index,
                                    placeholder: _vm.$t(
                                      "dashboard.launchpad.field.link_url"
                                    ),
                                    "data-vv-name": `link_url` + index,
                                    "data-vv-as": _vm.$t(
                                      "dashboard.launchpad.field.link_url"
                                    ),
                                    readonly: _vm.isReadOnly,
                                    "data-vv-delay": "500",
                                    trim: "",
                                  },
                                  model: {
                                    value: link.link_url,
                                    callback: function ($$v) {
                                      _vm.$set(link, "link_url", $$v)
                                    },
                                    expression: "link.link_url",
                                  },
                                }),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: true },
                                      expression: "{ required: true }",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: `link_text` + index,
                                    placeholder: _vm.$t(
                                      "dashboard.launchpad.field.link_text"
                                    ),
                                    "data-vv-name": `link_text` + index,
                                    "data-vv-as": _vm.$t(
                                      "dashboard.launchpad.field.link_text"
                                    ),
                                    readonly: _vm.isReadOnly,
                                    "data-vv-delay": "500",
                                    trim: "",
                                  },
                                  model: {
                                    value: link.link_text,
                                    callback: function ($$v) {
                                      _vm.$set(link, "link_text", $$v)
                                    },
                                    expression: "link.link_text",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          id: `DASHBOARD_LAUNCHPAD_LINK_ADD-${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkAdd(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: `DASHBOARD_LAUNCHPAD_LINK_ADD-${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.add")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          id: `DASHBOARD_LAUNCHPAD_LINK_REMOVE-${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkRemove(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "trash-can"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: `DASHBOARD_LAUNCHPAD_LINK_REMOVE-${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.remove")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showUrlError(index) },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "  " +
                                    _vm._s(
                                      _vm.errors.first("link_url" + index)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showTextError(index) },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "  " +
                                    _vm._s(
                                      _vm.errors.first("link_text" + index)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }