<template>
  <div :id="`PASSWORD_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="$t('user.set_password')" footer-class="footerClass"
      @hidden="hide"
      content-class="shadow" no-close-on-backdrop
    >

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <p class="password-hint">{{ $t('complete_registration.title_detail') }}
        <b-link id="LINK_PASS_INFO"><font-awesome-icon :icon="['far', 'circle-info']"/></b-link>
        <b-popover target="LINK_PASS_INFO" triggers="click blur" placement="top">
          <span v-html="passwordInfo"></span>
        </b-popover>
      </p>
            
      <div v-if="CompleteRegistrationFormState.LOADING === formState" class="text-center">
        <b-spinner label="Spinning" />
      </div>
      <b-form v-else-if="CompleteRegistrationFormState.HAS_VALID_TOKEN === formState">
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon :icon="['far', 'lock']" />
              </b-input-group-text>
            </b-input-group-prepend>
            <input type="password" class="last-child form-control" :class="{ 'is-invalid': showPasswordError }" id="inputPassword" placeholder="Enter Password" ref="password"
              :data-vv-as="$t('complete_registration.field.password')"
              data-vv-name="password"
              data-vv-delay="500"
              v-model="password"
              v-validate="{ required: validate }"
              :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'password')"
              />
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPasswordError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('password') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon :icon="['far', 'lock']" />
              </b-input-group-text>
            </b-input-group-prepend>
            <input type="password" class="last-child form-control" :class="{ 'is-invalid': showConfirmPasswordError }"  id="confirmPass" placeholder="Confirm Password"
              :data-vv-as="$t('complete_registration.field.password_confirm')"
              data-vv-name="confirmPass"
              data-vv-delay="500"
              v-model="confirmPass"
              v-validate="{ required: validate, confirmed: 'password' }"
              />
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showConfirmPasswordError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('confirmPass') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      
      <template v-slot:modal-footer="{}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="hide">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>

import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import { authenticationService } from '@/services';

export default {
  name: 'PasswordModal',
  components: { 
  },
  props: {
    show:      { type: Boolean, required: true },
    tokenId: { type: String, default: null},
    register: { type: Boolean, default: true}
  },
  data() {
    return {
      id:        { type: String,   default: `PASSWORD_NEW_${strRandom(5)}` },
      state: {
        modalShow:           false
      },
      alertMsg: null,
      baseUrl: process.env.BASE_URL,
      formState: 0,
      password: null,
      confirmPass: null,
      versionNumber: null,
      validate: false,
      actionInProgress: false,
      passwordRules: {}
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.registrationToken = this.tokenId;
        this.state.modalShow = newValue;
      }
    }
  },
  created() {
    this.validate = true;
    // Get version number for footer
    const self = this;
    this.$store.dispatch('data/status').then(value => {
      self.versionNumber = value.version;
      document.title = `Projectal - ${value.companyName}`;
    })
    .catch(e => {
      this.versionNumber = "N/A";
      document.title = 'Projectal';
    });

    this.fieldValidateUtil = fieldValidateUtil;
    this.CompleteRegistrationFormState = Object.freeze({
      LOADING: 1,
      HAS_INVALID_TOKEN: 2,
      HAS_VALID_TOKEN: 3,
      UPDATE_SUCCESS: 4,
      UPDATE_FAILURE:5,
    })
    this.formState = this.CompleteRegistrationFormState.HAS_VALID_TOKEN;
    this.registrationToken = this.tokenId;
    
    this.$store.dispatch('data/schemaPassword', {type: 'password' })
    .then((response) => {
      this.passwordRules = response;
    });
    
  },
  beforeUnmount() {
    this.validate = false;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.CompleteRegistrationFormState = null;
  },
  computed: {
    isSubmitDisabled() {
      return !this.password || !this.confirmPass || !!this.errors.first('password') || !!this.errors.first('confirmPass');
    },
    passwordInfo() {
      const mandatoryTexts = [];
      const optionalTexts = [];
      for (const key of Object.keys(this.passwordRules)) {
        const rule = this.passwordRules[key];
        let txt = '';
        if (Object.keys(rule).length === 2) {
          const key1 = Object.keys(rule)[0];
          const key2 = Object.keys(rule)[1];
          txt = this.toText(key1, [rule[key1], rule[key2]]);
          if (key1 !== 'permit_char_min' &&
              key1 !== 'use') {
            
            if (/\d$/.test(key)) {
              optionalTexts.push(txt);
            }
            else {
              mandatoryTexts.push(txt);
            }
          }
        }
        else if (rule['not_matching_email']) {
          txt = this.toText('not_matching_email', []);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
        else if (rule['not_matching_user']) {
          txt = this.toText('not_matching_user', []);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
        else if (rule['no_repeat_char_max']) {
          txt = this.toText('no_repeat_char_max', [rule['no_repeat_char_max']+1]);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
      }
      
      let ret = '';
      
      // mandatory rules
      if (mandatoryTexts.length !== 0) {
        ret += `<p class="text-left mb-0">${ this.$i18n.t('reset_password.password_info.must')}</p>
              <ul class="text-left pl-3">`;
      }
      
      for (const text of mandatoryTexts) {
        ret += `<li>${text}</li>`;
      }
      ret += `</ul>`;
      
      // optional rules
      if (optionalTexts.length !== 0) {
        ret += `<p class="text-left mb-0">${ this.$i18n.t('reset_password.password_info.optional', [this.passwordRules.rules.min])}</p>
              <ul class="text-left pl-3">`;
      }
      
      for (const text of optionalTexts) {
        ret += `<li>${text}</li>`;
      }
      ret += `</ul>`;
      return ret;
    },
    showConfirmPasswordError() {
      return fieldValidateUtil.hasError(this.errors, 'confirmPass');
    },
    showPasswordError() {
      return fieldValidateUtil.hasError(this.errors, 'password');
    },
    errorShow() {
      return this.alertMsg != null;
    },
  },
  methods: {
    toText(key, val) {
      if (key === 'use' ||
          key === 'permit_char_min') {
        return '';   
      }
      return this.$t(`reset_password.${key}`, val);
    },
    async ok() {
      this.actionInProgress = true;
      const action = this.register ? 'changePasswordForRegister' : 'changePasswordForReset';
      authenticationService[action](this.password, this.password, this.registrationToken)
      .then(() => {
        this.$emit('update:show', false);
        this.$emit('success');
      })
      .catch(e => {
        const response = e.response;
        if (response) {
          if (406 === response.status) {
            this.errors.add({
              field: 'password',
              msg: this.$t('complete_registration.error.invalid_password')
            })
          } else if (404 === response.status) {
            this.errors.add({
              field: 'password',
              msg: this.$t('complete_registration.error.invalid_token_no_smtp')
            })
          }
        } else {
          this.formState = this.CompleteRegistrationFormState.UPDATE_FAILURE;
        }
      })
      .finally(() => {
        this.actionInProgress = false;
      });
    },
    hide() {
      this.$emit('update:show', false);
      this.$emit('success');
    },
    dismissAlert() {
      this.alertMsg = null;
    },
  }
}
</script>
<style>
.password-hint {
  text-align: center;
}
</style>
